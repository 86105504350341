export const price_type = [
    {
        value:'1',
        label:'市场价'
    },{
        value:'2',
        label:'出厂价'
    }
]

export const comefrom = [
    {
        value:'1',
        label:'平台'
    },{
        value:'2',
        label:'会员'
    }
]

export const status = [
    {
        value:'0',
        label:'全部'
    },{
        value:'1',
        label:'待审核'
    },{
        value:'2',
        label:'已通过'
    },{
        value:'3',
        label:'已驳回'
    }
]
